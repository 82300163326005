<template>
  <div class="col-12">
    <div class="card">
        <div class="card-header">
            <h5>
                التحكم في المعلمين 
                <button class="btn btn-primary btn-sm" @click="$router.push(xxx ? '/_lessons/teachers/add' : '/teachers/add')">
                    <i class="fa fa-plus"></i>
                    اضافة
                </button>
                &nbsp;
                <button class="btn btn-success btn-sm" v-b-modal.m>
                    <i class="fa fa-paper-plane"></i>
                    ارسال البيانات للمعلمين
                </button>
                &nbsp;
                <button class="btn btn-warning btn-sm" @click="print()">
                    <i class="fa fa-print"></i>
                    طباعة الجدول
                </button>
            </h5>
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-sm table-bordered table-sm table-striped" id="table">
                    <thead>
                        <th>
                            الاسم
                        </th>
                        <th>
                            رقم الهوية
                        </th>
                        <th>
                            الجوال
                        </th>
                        <th>
                            اسم المستخدم
                        </th>
                        <th>
                            كلمة المرور
                        </th>
                        <th class="hideme">
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="teacher in teachers" :key="teacher._id">
                            <td>
                                {{ teacher.name }}
                            </td>
                            <td>
                                {{ teacher.number }}
                            </td>
                            <td>
                                {{ teacher.phone }}
                            </td>
                            <td>
                                {{ teacher.username }}
                            </td>
                            <td>
                                {{ teacher.password }}
                            </td>
                            <td class="hideme">
                                <button style="border-radius: 0px;" class="btn btn-success btn-sm" @click="link(teacher)">
                                    <i class="fa fa-link"></i> رابط الدخول
                                </button>
                                <button style="border-radius: 0px;" class="btn btn-primary btn-sm" @click="$router.push((xxx ? '/_lessons/teachers/edit/' : '/teachers/edit/') + teacher._id)">
                                    <i class="fa fa-edit"></i> تعديل
                                </button>
                                <button style="border-radius: 0px;" class="btn btn-outline-danger btn-sm" @click="deleteTeacher(teacher._id)">
                                    <i class="fa fa-trash"></i> حذف
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="m" title="ارسال بيانات الدخول للمعلمين" hide-footer>
        <div class="form-group">
          <label for="">الرسالة</label>
          <textarea class="form-control" v-model="message" rows="8"></textarea>
        </div>
        <div class="row">
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-success btn-block"  @click="send('whatsapp')">
                    <i class="fa fa-whatsapp"></i>
                        واتس تلقائي
                </button>
            </div>
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-success btn-block" @click="send('wa')">
                    <i class="fa fa-whatsapp"></i>
                    اداة المتصفح
                </button>
            </div>
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-primary btn-block"  @click="send('sms')">
                    <i class="fa fa-mobile"></i>
                    SMS
                </button>
            </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BModal
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            xxx: window.location.href.includes('_lessons'),
            user: JSON.parse(localStorage.getItem('user')),
            teachers: [],
            message: `المعلم {name}
اسم المستخدم {username}
كلمة المرور: {password}
رابط الدخول لحسابك مباشرة: 
{link}

رابط تحميل التطبيق للاندرويد:
https://bit.ly/46zcxkW
رابط تحميل التطبيق للايفون:
https://apple.co/3Aduxp2`
        }
    },
    created(){
        if(!checkPer("lessons")){
            this.$router.push('/NotPermitted')
            return false;
        }
        this.getTeachers()
    },
    methods: {
        getTeachers(){
            var g = this;
            $.post(api + '/user/teachers/list', {
                jwt: this.user.jwt
            }).then(function(r){
                g.teachers = r.response
            })
        },
        deleteTeacher(id){
            var g = this;
            if(confirm('متأكد من حذف المعلم نهائيا؟')){
                $.post(api + '/user/teachers/delete', {
                    jwt: this.user.jwt,
                    id: id
                }).then(function(r){
                    g.getTeachers()
                })
            }
        },
        link(teacher){
            prompt("رابط دخول المعلم", 'https://' + window.location.hostname + '/_xteacher/j/' + btoa(teacher.username + ',' + teacher.password))
        },
        print(){
            var divToPrint=document.getElementById("table");
            var newWin= window.open("");
            newWin.document.write(`
            <style>
            html, body{
                direction: rtl
            }
            .hideme{
                display:none;   
            }
            table, td, th {
            border: 1px solid;
            }

            table {
            width: 100%;
            border-collapse: collapse;
            }
            </style>
            ` + divToPrint.outerHTML);
            newWin.document.close()
            newWin.print();
        },

        send(method){
            var g = this;
            var messages = [];
            g.teachers.forEach(function(a){
                messages.push({
                    phones: [a.phone],
                    message: g.message.replace("{name}", a.name).replace("{username}", a.username).replace("{password}", a.password).replace("{link}", `({${'https://' + window.location.hostname + '/_xteacher/j/' + btoa(a.username + ',' + a.password)}})`)
                })
            })
            $.post(api + '/user/general/short-links', {
                jwt: this.user.jwt,
                arr: JSON.stringify(messages),
            }).then(function(rx){
                if(method == 'wa'){
                    $("#sendmodalwaarray").val(JSON.stringify(rx.response))
                    $("#sendmodalwatype").val("lessons")
                    $("#sendWAModal").click()
                }else{
                    if(!confirm(`متأكد من ارسال ${messages.length} رسالة؟`)){return false;}
                    $.post(api + '/user/messages/send-arr', {
                        jwt: g.user.jwt,
                        arr: JSON.stringify(rx.response),
                        method: method,
                        type: "lessons"
                    }).then(function(r){
                        g.loaded = true;
                        if(r.status != 100){
                            alert(r.response, 200)
                        }else{
                            alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                        }
                    }).fail(function(){
                        alert("حدث خطأ", 200)
                    })
                }
            }).fail(function(){
                    alert("حدث خطأ.", 200)
                })
        }
    }
}
</script>

<style>

</style>